<template>
    <div>
      <h1>Política de Privacidade</h1>
      <p>Bem-vindo ao Socializee! Esta Política de Privacidade descreve como coletamos, usamos e protegemos suas informações pessoais ao usar nosso aplicativo de rede social.</p>
  
      <h2>1. Informações que coletamos</h2>
      <p>Coletamos os seguintes tipos de informações:</p>
      <ul>
        <li><strong>Informações Pessoais:</strong> Nome, endereço de e-mail, número de telefone, foto de perfil, etc.</li>
        <li><strong>Informações de Uso:</strong> Dados sobre como você interage com o aplicativo, como postagens, curtidas, comentários e mensagens privadas.</li>
        <li><strong>Informações do Dispositivo:</strong> Dados do dispositivo, incluindo endereço IP, localização aproximada, tipo de dispositivo, sistema operacional, etc.</li>
      </ul>
  
      <h2>2. Como usamos suas informações</h2>
      <p>Usamos suas informações para fornecer e melhorar nossos serviços, incluindo:</p>
      <ul>
        <li>Personalizar sua experiência no Socializee;</li>
        <li>Facilitar a comunicação com outros usuários;</li>
        <li>Prover suporte técnico e responder às suas dúvidas;</li>
        <li>Monitorar e analisar o uso do aplicativo para fins de melhoria contínua;</li>
        <li>Proteger a segurança dos usuários e do aplicativo.</li>
      </ul>
  
      <h2>3. Compartilhamento de Informações</h2>
      <p>Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:</p>
      <ul>
        <li>Quando você consente com o compartilhamento;</li>
        <li>Para cumprir obrigações legais ou regulamentares;</li>
        <li>Para proteger os direitos, propriedade e segurança de nossos usuários e do Socializee.</li>
      </ul>
  
      <h2>4. Segurança de Dados</h2>
      <p>Implementamos medidas de segurança para proteger suas informações pessoais. No entanto, nenhum método de transmissão ou armazenamento é 100% seguro. Portanto, não podemos garantir a segurança absoluta de seus dados.</p>
  
      <h2>5. Seus Direitos</h2>
      <p>Você tem o direito de:</p>
      <ul>
        <li>Acessar, corrigir ou excluir suas informações pessoais;</li>
        <li>Solicitar a limitação ou objeção ao processamento de suas informações;</li>
        <li>Retirar o consentimento a qualquer momento.</li>
      </ul>
  
      <h2>6. Alterações nesta Política</h2>
      <p>Reservamo-nos o direito de modificar esta política de privacidade a qualquer momento. Notificaremos sobre quaisquer alterações significativas através do próprio aplicativo ou por e-mail.</p>
  
      <h2>7. Contato</h2>
      <p>Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco pelo e-mail: <strong>support@socializee.com.br</strong>.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy'
  }
  </script>
  
  <style scoped>
  ul {
    list-style-position: inside; /* Faz os dots ficarem dentro da área do texto */
    margin-left: 0; /* Remove a margem esquerda padrão */
    padding-left: 20px; /* Define o espaço entre o texto e os dots */
  }
  
  li {
    margin-bottom: 5px; /* Controla o espaço entre os itens da lista */
  }
  </style>