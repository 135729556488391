<template>
    <div>
      <h1>Termos e Condições</h1>
      <p>Bem-vindo ao Socializee! Ao usar nosso aplicativo, você concorda com os seguintes termos e condições. Leia atentamente antes de utilizar o Socializee.</p>
  
      <h2>1. Aceitação dos Termos</h2>
      <p>Ao acessar e utilizar o Socializee, você concorda em cumprir estes Termos e Condições. Se você não concordar com qualquer parte destes termos, não poderá usar o aplicativo.</p>
  
      <h2>2. Uso do Socializee</h2>
      <p>Você se compromete a usar o Socializee de maneira adequada e a não violar nenhuma lei local, nacional ou internacional, nem praticar atividades como:</p>
      <ul>
        <li>Publicar conteúdo ofensivo, difamatório ou ilegal;</li>
        <li>Usar o aplicativo para fins comerciais não autorizados;</li>
        <li>Distribuir vírus ou outro material malicioso;</li>
        <li>Violar direitos de propriedade intelectual.</li>
      </ul>
  
      <h2>3. Conta de Usuário</h2>
      <p>Você é responsável por manter a confidencialidade de suas credenciais de login e por todas as atividades que ocorram sob sua conta. Caso identifique uso não autorizado, deverá notificar-nos imediatamente.</p>
  
      <h2>4. Propriedade Intelectual</h2>
      <p>Todo o conteúdo e materiais disponíveis no Socializee, incluindo textos, gráficos, logotipos, ícones e imagens, são de nossa propriedade ou de nossos licenciadores e estão protegidos por leis de direitos autorais e de marcas comerciais.</p>
  
      <h2>5. Suspensão e Encerramento</h2>
      <p>Podemos suspender ou encerrar sua conta a qualquer momento se violar estes termos ou se envolvê-lo em atividades fraudulentas, ilegais ou abusivas no uso do Socializee.</p>
  
      <h2>6. Limitação de Responsabilidade</h2>
      <p>Não nos responsabilizamos por quaisquer danos diretos, indiretos, incidentais ou consequentes resultantes do uso ou incapacidade de uso do Socializee. O uso do aplicativo é por sua conta e risco.</p>
  
      <h2>7. Alterações nos Termos</h2>
      <p>Reservamo-nos o direito de modificar estes Termos e Condições a qualquer momento. Você será notificado de quaisquer alterações importantes e, ao continuar a usar o aplicativo, concorda com os termos revisados.</p>
  
      <h2>8. Contato</h2>
      <p>Se você tiver dúvidas sobre estes Termos e Condições, entre em contato conosco pelo e-mail: <strong>support@socializee.com.br</strong>.</p>
    </div>
  </template>

<script>
export default {
  name: 'TermsAndConditions'
}
</script>

<style scoped>
ul {
  list-style-position: inside; /* Faz os dots ficarem dentro da área do texto */
  margin-left: 0; /* Remove a margem esquerda padrão */
  padding-left: 20px; /* Define o espaço entre o texto e os dots */
}

li {
  margin-bottom: 5px; /* Controla o espaço entre os itens da lista */
}
</style>