<template>
  <header>
    <img src="@/assets/logo.png" alt="Logo Socializee" class="logo" />
    <h1 style="font-size: 24px;">Socializee</h1>
    <button onclick="window.location.href='https://play.google.com/apps/testing/br.com.socializee';" class="buttonBreve">Teste agora</button>
  </header>
  <div class="landing-page">
    <div class="texto">
      <div class="textoJuntos">
        <div>
          <h1>Conecte-se com Comunidades que Importam.</h1>
          <p style="font-size: 18px;">
            Descubra o poder da conexão com o Socializee. Crie ou participe de
            comunidades vibrantes e inspiradoras. Seja livre para se expressar e
            construir seu próprio espaço. Junte-se a nós e construa a sua
            comunidade agora!
          </p>
          <div style="display: flex; flex-direction: column; align-items: start;">
          
           <button onclick="window.location.href='https://play.google.com/store/apps/details?id=br.com.socializee';" 
           class="buttonInsc">Exclusivo no Google Play</button>
            <img class="imgEmbaixo" src="@/assets/ladingpage-group.png" />
          </div>
        </div>
        <div class="image-container">
          <img src="@/assets/devices.png" class="foreground-image" />
          <img src="@/assets/linhas.png" class="background-image" />
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <p>
      Visite nossa
      <a href="https://socializee.com.br/documents/privacy" target="_blank">
        Política de Privacidade
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
}
</script>

<style scoped src="./landingPage.css"></style>
