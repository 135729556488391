import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/landing-page/landingPage.vue'
import PrivacyPolicy from '../views/documents/privacyPolicy.vue' // Importa o componente
import TermsAndConditions from '../views/documents/terms.vue'

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/documents/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/documents/terms',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
